<template>
  <div id="app">
    <End />
  </div>
</template>

<script>
import End from "./components/End.vue";

export default {
  name: "app",
  components: {
    End
  }
};
</script>
