<template>
  <div>
    <section>
      <div id="icons" class="icondiv">
        <img class="star4" id="iconstay" src="~@/assets/starticon2.png" />
        <img
          class="sticon"
          id="iconb"
          src="~@/assets/starticon.png"
          v-on:click="startdown()"
          style="display: none"
        />
        <img
          class="sticon"
          id="icona"
          src="~@/assets/starticon2.png"
          style="display: none"
        />
      </div>
      <div v-if="dispalyData && gmData && show >= 1 && getready">
        <div v-if="show >= 1" class="bggif">
          <div class="titlediv">
            <img class="sttitle" src="~@/assets/titlelogo.png" />
          </div>
          <div v-for="(disp, index) in dispalyData" v-bind:key="index">
            <div class="mondai">
              <div class="monme">
                {{ disp.num }}:{{ disp.title }}
                <br />
                現地：{{ disp.locate }}
              </div>
              <div class="names">
                作問担当：
                <div v-html="brconma(disp.saku)"></div>
                現地担当：
                <div v-html="brconma(disp.gen)"></div>
                <br />
                セブン班：
                <div v-html="brconma(disp.sev)"></div>
                <br />
                甜菜：
                <div v-html="brconma(disp.ten)"></div>
                <br />
                勇者：
                <div v-html="brconma(disp.yuu)"></div>
              </div>
            </div>
          </div>
          <p class="hd">GM</p>
          <div class="gmname" v-for="gm in gmData" v-bind:key="gm">
            {{ gm }}
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <p class="hdt">デバッグ班（解いた数順）</p>
          <p class="gmname1">
            ひゃん(班長)/うりよしきば/はくまい/闇の隠居/あみみん
          </p>
          <p class="gmname1">(ふつうの)アザラシ/Ren/ut/kaialex22</p>
          <p class="gmname1">satos/じるし/きらく/とりのみそかつ/海老</p>
          <p class="gmname1">紗那/まんてん/Naka/そ〜/Hyu</p>
          <p class="hdt">解説校閲班</p>
          <p class="gmname1">Ren/あみみん/よみ</p>
          <p class="hdt">システム班</p>
          <p class="gmname1">satos/ut/スイギン</p>
          <p class="gmname1">とりのみそかつ/ひゃん/紗那</p>
          <p class="hdt">エンドロール制作</p>
          <p class="gmname1">ましー</p>
          <p class="hdt">デザイン班</p>
          <p class="gmname1">けー(班長)/海老/三ツ浜ともき</p>
          <p class="hdt">全問デバッグ班</p>
          <p class="gmname1">さやちぃ。</p>
          <p class="hdt">公式アカウント運営</p>
          <p class="gmname1">ましー/kaialex22/ひゃん/Ren</p>
          <p class="gmname1">よー１/海老/(ふつうの)アザラシ/さやちぃ。</p>
          <p class="gmname1">なすのてんぷら/紗那</p>
          <p class="hdtm">メインGM</p>
          <p class="gmname1">ましー</p>
          <br />
          <br />
          <p class="flt" style="margin-bottom: 0px">Thank you for playing!</p>
          <br />
          <transition>
            <div id="owari" class="fin">
              atgt2023w
              <br />おわり
            </div>
          </transition>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "End",
  data() {
    return {
      dispalyData: null,
      gmData: null,
      scTime: null,
      show: -1,
      scSpeed: 5,
      dly: 3000,
      scrollY: 0,
      getready: null,
      isIOS: false,
    };
  },
  created: function () {
    this.checkDevice();
    let host = window.location.origin;
    let myself = this;
    axios.get(host + "/data.json").then(function (response) {
      myself.dispalyData = response.data;
      myself.show = 1;
    });
    axios
      .get(host + "/gmdata.json")
      .then((response) => (this.gmData = response.data));
  },
  mounted() {
    this.getready = true;
    this.tglid();
  },
  methods: {
    tgl() {
      this.show = 1;
    },
    brconma(words) {
      return words.replace(/,/g, "<br>");
    },
    tglid() {
      let icons = document.getElementById("iconstay");
      icons.style.display = "none";
      let iconb = document.getElementById("iconb");
      iconb.style.display = "block";
    },
    checkDevice() {
      const ua = navigator.userAgent;
      this.isIOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
    },
    startdown() {
      console.log("down");
      let iconb = document.getElementById("iconb");
      iconb.style.display = "none";
      let icona = document.getElementById("icona");
      icona.style.display = "block";
      this.show = 2;
      let step = this.isIOS ? 20 : 5;
      const interval = 100;
      setInterval(
        function () {
          scrollBy(0, step); // スクロール位置を移動
        }.bind(this),
        interval
      );
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "cinecaption";
  src: url("~@/assets/cinecaption227.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

.fontall {
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}

.tback {
  /* width: 80vw; */
  font-size: 3rem;
  text-align: center;
}

.monme {
  /* width: 80vw; */
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: whitesmoke;
  font-size: 1.2rem;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.names {
  /* width: 80vw; */
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: whitesmoke;
  font-size: 1.5rem;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 rgb(0, 0, 0), -1px -1px 0 #000, -1px 1px 0 #000,
    1px -1px 0 #000, 0px 1px 0 #000, 0-1px 0 #000, -1px 0 0 #000, 1px 0 0 #000; */
}
.w3w {
  font-size: 0.9rem;
}

.gmname {
  /* width: 80vw; */
  color: whitesmoke;
  font-size: 2rem;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.gmname1 {
  /* width: 80vw; */
  font-size: 1.25rem;
  color: whitesmoke;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, */
    /* 1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.hd {
  /* width: 80vw; */
  margin-top: 3rem;
  color: crimson;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, */
    /* 1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.hdt {
  /* width: 80vw; */
  color: crimson;
  font-size: 1.5rem;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}

.flt {
  /* width: 80vw; */
  color: whitesmoke;
  font-size: 3rem;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.hdtmm {
  /* width: 80vw; */
  color: crimson;
  font-size: 1.2rem;
  margin-bottom: 0px;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.hdtm {
  /* width: 80vw; */
  color: crimson;
  font-size: 1.5rem;
  margin-bottom: 0px;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.fin {
  color: whitesmoke;
  font-size: 3rem;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "cinecaption", sans-serif;
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
}
.bggif {
  background: url("~@/assets/bggif.gif");
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  background-size: 100% 100vh;
  z-index: -1;
  background-attachment: scroll;
  background-repeat: repeat-y;
  /* background-attachment: fixed; */
  /* background-size: contain; */
}
.icondiv {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #627BA0;
}
.titlediv {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sttitle {
  width: 90vw;
}

.mac {
  width: 50vw;
}

.sticon {
  width: 30vw;
}

video {
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  -moz-background-size: 100vw 100vh;
  background-size: 100vw 100vh;
  z-index: -1;
}
.header-title {
  position: relative; /*必ず必要*/
  z-index: 2; /*必ず必要*/
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

section {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.mondai {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.star4 {
  width: 30vw;
  animation: r4 60s steps(60) infinite;
}
</style>


