import Vue from 'vue'
import App from './App.vue'
// import VueAwesomeSwiper from 'vue-awesome-swiper' 
import BootstrapVue from 'bootstrap-vue'
import VueParticles from 'vue-particles'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(VueParticles)
Vue.use(BootstrapVue)
// Vue.use(VueAwesomeSwiper) 
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
